// src/WeddingLandingPage.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

function WeddingLandingPage() {
  return (
    <div className="WeddingLandingPage">
      <header>
        <h1>Welcome to Jennifer and Simon's Wedding</h1>
      </header>
      


      <div className="container">
        <p>Please share your photos using the shared album:</p>
        <a href="https://photos.app.goo.gl/YvsSCujgFZByFtny9" className="button">Shared Album</a>
        <a href="https://www.theknot.com/us/jennifer-wong-and-simon-ulleland-oct-2024" className="button">Wedding Home Page</a>
      </div>
      
      {/* Footer section for toastmasters' phone number */}
      <footer className="toastmasters-contact">
        <p>If you have any questions, feel free to reach out to our toastmasters:</p>
        <p><a href="tel:+4797874697">Nicolas: +47 97 87 46 97</a></p>
        <p><a href="tel:+48794139420">Teresa: +48 79 41 394 20</a></p>
      </footer>
      </div>
  );
}

export default WeddingLandingPage;